import React from "react"
import { Layout, DownloadFrom, SEO } from "../components"
import {
  okPhotoPhone,
  iconCamera,
  iconDoc,
  iconTenge,
  photoCamera,
  speed,
  bus,
  radar,
  ultrasound,
  stop,
  sergek,
} from "../assets"
import "./photo.css"
import "./main.css"

const OKPhoto = () => {
  return (
    <Layout>
      <SEO
        title="Фотографии мест нарушений ПДД | OKauto"
        description="Приложение которое мы разработали позволяет получать подробную информацию о нарушении ПДД - фотографии мест нарушения с пояснениями. Регистрируйтесь и получайте скидку на оплату штрафа сроком не позднее недели"
      />
      <div className="lh-copy pages__padding animation">
        <div className="flex-l w-100 justify-center ok__photo__bg">
          <div className="flex justify-center">
            <img
              alt="как получить фото и место штрафа онлайн Казахстан"
              title="фото с места нарушения ПДД в телефоне"
              src={okPhotoPhone}
              className="mb0 self-center ok_photo_phone"
            />
          </div>
          <div className="w-40-l">
            <div className=" tl-l tc">
              <h1 className="f1-l ipad__font__header f2 small__main__header ph2">
                Фото и место нарушения
              </h1>
              <p className="f3-l ipad__font__text f4 w-90-l mb0 ph3">
                В мобильном приложении OKauto Вы также можете получить
                фотоматериалы с момента нарушения.{" "}
                <span className="di-ns dn">
                  Данная информация предоставляется при просмотре результата
                  запроса, после ввода корректных данных.
                </span>
              </p>
            </div>
            <div className="flex justify-start-l ml5-l justify-center mt5-l mt4 ipad__pro__pagging__okphoto pb3">
              <DownloadFrom />
            </div>
          </div>
        </div>
        <div className=" ph6-l ph5-m ph3 pb6-ns pb4 mt5">
          <h2 className="f1-l f2 small__header ipad__font__header">
            Фотоматериалы
          </h2>
          <div className="flex-l mt4">
            <div className="flex flex-column w-50-l">
              <div className="flex mb4 ">
                <img
                  alt="iconDoc"
                  title="iconDoc"
                  src={iconDoc}
                  className="mr4"
                />
                <span className="f4-l f5 ipad__font__text">
                  Предписание – документ упрощенной окончательной формы
                  административного производства, который подразумевает фиксацию
                  момента совершения нарушения правил дорожного движения, за
                  которые предусмотрена административная ответственность.
                </span>
              </div>
              <div className="flex mb4">
                <img
                  alt="iconCamera"
                  title="iconCamera"
                  src={iconCamera}
                  className="mr4"
                />
                <span className="f4-l f5 ipad__font__text">
                  Подобные штрафы выписываются на авто и имеют фотоматерилы с
                  момента нарушения. Следует учесть, снимки будут доступны
                  только при условии, что информация доступна в государственной
                  базе штрафов.
                </span>
              </div>
              <div className="flex mb4">
                <img
                  alt="iconTenge"
                  title="iconTenge"
                  src={iconTenge}
                  className="mr4"
                />
                <span className="f4-l f5 ipad__font__text">
                  Также согласно действующему законодательству РК, предусмотрены
                  скидки в размере 50% по дорожным нарушениям,
                  зарегистрированными фото и видеофиксациями.
                </span>
              </div>
            </div>
            <div className="flex flex-column w-50-l w-100">
              <img
                alt="получить фото нарушения с камер Сергек Жолак"
                title="фото штрафа с дорожных камер в прилржении OKauto"
                src={photoCamera}
                className="mb0"
              />
              <p className="ph3-l  ph2 ipad__font__citation i">
                На сегодняшний день в Алматы функционирует автоматизированная
                информационная система{" "}
                <span className="blue">"Безопасность дорожного движения"</span>{" "}
                (АИС "БДД"), а в Астане система наблюдения{" "}
                <span className="blue">«Сергек»</span>.
              </p>
            </div>
          </div>
          <div className="sergek__wrapper white flex mt4">
            <div className="flex flex-column ph5-l ph4-m ph3 pv4-l pb4">
              <h2 className="f1-l f2 ipad__font__header small__header mv4">
                А вы знаете что Сергек...
              </h2>
              <div className="dt">
                <div className="dt-row-ns">
                  <div className="dtc-ns">
                    <div className="flex ">
                      <img
                        alt="speed"
                        title="speed"
                        src={speed}
                        className="mr3"
                      />
                      <div className="w-60-l w-75-m">
                        <span>Скорость фиксирует специальным алгоритмом</span>
                      </div>
                    </div>
                  </div>
                  <div className="dtc-ns">
                    <div className="flex ">
                      <img
                        alt="radar"
                        title="radar"
                        src={radar}
                        className="mr3"
                      />
                      <div className="w-60-l w-75-m">
                        <span>Дальность действия системы около 50 метров</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dt-row">
                  <div className="dtc-ns">
                    <div className="flex ">
                      <img alt="bus" title="bus" src={bus} className="mr3" />
                      <div className="w-60-l w-75-m">
                        <span>Фиксирует выезд на полосу BUS LANE</span>
                      </div>
                    </div>
                  </div>
                  <div className="dtc-ns">
                    <div className="flex ">
                      <img
                        alt="ultrasound"
                        title="ultrasound"
                        src={ultrasound}
                        className="mr3"
                      />
                      <div className="w-60-l w-75-m">
                        <span>
                          Классические антирадары не улавливают камеры
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dtc-ns">
                  <div className="flex ">
                    <img alt="stop" title="stop" src={stop} className="mr3" />
                    <div className="w-60-l w-75-m">
                      <span>Фиксирует пересечение СТОП линий</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <img
              alt="какие нарушения фиксирует Сергек в Казахстане"
              title="за что штрафует Сергек"
              src={sergek}
              className="mb0  sergek ok_photo_sergek"
            />
          </div>
          <h2 className="f1-l f2 small__header ipad__font__header mt5">
            Место нарушения
          </h2>

          <p className="f4-l f5 ipad__font__text">
            Если информация о месте нарушения имеется в государственной базе
            штрафов, сервис покажет вам точный адрес по которому произошло
            нарушение. Если данная информация недоступна, в приложении
            отобразится город на основании названия подразделения ГАИ,
            выписавшего штраф.
          </p>
          <p className="f5-l f6 ipad__font__citation gray">
            К сожалению, наш сервис не влияет на полноту доступных сведений в
            источниках информации о штрафах, тем не менее мы каждый день
            работаем над расширением базы источников информации, чтобы
            предоставлять все возможные сведения о ваших штрафах.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default OKPhoto
